// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-conference-src-pages-index-js": () => import("./../node_modules/gatsby-theme-conference/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-conference-src-pages-live-js": () => import("./../node_modules/gatsby-theme-conference/src/pages/live.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-pages-live-js" */),
  "component---node-modules-gatsby-theme-conference-src-pages-schedule-js": () => import("./../node_modules/gatsby-theme-conference/src/pages/schedule.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-pages-schedule-js" */),
  "component---node-modules-gatsby-theme-conference-src-pages-speakers-js": () => import("./../node_modules/gatsby-theme-conference/src/pages/speakers.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-pages-speakers-js" */),
  "component---node-modules-gatsby-theme-conference-src-templates-session-js": () => import("./../node_modules/gatsby-theme-conference/src/templates/session.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-templates-session-js" */),
  "component---node-modules-gatsby-theme-conference-src-templates-speaker-js": () => import("./../node_modules/gatsby-theme-conference/src/templates/speaker.js" /* webpackChunkName: "component---node-modules-gatsby-theme-conference-src-templates-speaker-js" */)
}

