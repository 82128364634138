/** @jsx jsx */
import { jsx, Container } from 'theme-ui'

export default props =>
  <div
    id='purchase'
    sx={{
      py: [ 5, 6],
    }}>
    <Container>
      CommCon is entirely free to attend thanks to our fantastic sponsors.
    </Container>
  </div>